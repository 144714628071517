<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="showPopup"
    class="Popup"
    :style="{ bottom: bottomOffset + 'px'}"
  >
    <div
      class="Popup-modal"
      :class="getBrand"
    >
      <i
        class="Icon Icon-close Close-popup-modal"
        @click="closeModal"
      />
      <div
        v-if="popupImage && ctabuttontext && ctabuttonlink"
        class="Popup-image-wrapper"
      >
        <img :src="popupImage">
      </div>
      <div :class="['Popup-content', { 'Full-width': !popupImage || popupImage && !ctabuttontext && !ctabuttonlink }]">
        <p
          v-if="heading"
          class="Popup-heading hb-font-text-bold u-mb5 u-mr10"
        >
          {{ heading }}
        </p>
        <p
          v-if="body"
          class="Popup-body hb-font-text-regular u-mb10"
          v-html="body"
        />
        <div
          v-if="ctabuttontext && ctabuttonlink"
          class="Popup-buttons u-mt15"
        >
          <b-button
            v-if="lemonaid"
            type="is-subtle"
            expanded
            @click="handleButtonClick(ctabuttonlink, 'button tap', 'Clicked on Visit Lemonaid button.', 'Lemonaid popup')"
          >
            {{ ctabuttontext }}
          </b-button>
          <b-button
            v-else
            type="is-subtle"
            expanded
            @click="handleButtonClick(ctabuttonlink, 'button tap', 'Clicked Popup CTA', 'Popup')"
          >
            {{ ctabuttontext }}
          </b-button>
        </div>
        <div
          v-else
          class="Popup-buttons u-mt15"
        >
          <AppButtons
            :android-download-image="androidDownloadImage"
            :play-store-url="playStoreUrl"
            :ios-download-image="iosDownloadImage"
            :app-store-url="appStoreUrl"
            :tracking-screen="'Popup'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrackingProvider from 'Shared/components/tracking/providers/segment-provider'
import AppButtons from './AppButtons'

export default {
  name: 'Popup',

  components: {
    AppButtons: AppButtons
  },

  props: {
    heading: String,
    type: String,
    body: String,
    popupImage: String,
    ctabuttontext: String,
    ctabuttonlink: String,
    appStoreUrl: String,
    playStoreUrl: String,
    iosDownloadImage: String,
    androidDownloadImage: String,
    lemonaidEnabled: Boolean,
    alternativePopup: Object
  },

  data () {
    return {
      showPopup: false,
      lemonaid: false,
      bottomOffset: 0,
      isSessionStorageAvailable: false
    }
  },

  computed: {
    getBrand () {
      return window.location.host.indexOf('asda') > -1 ? 'Asda' : ''
    }
  },

  created () {
    if (this.lemonaidEnabled && window.zavamed.country === 'us') {
      this.showAlternativeLemonaidContent()
    }

    this.isSessionStorageAvailable = this.checkIfSessionStorageAvailable()

    if (this.heading !== undefined) {
      window.addEventListener('scroll', this.handleScroll)
    }
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    openModal () {
      this.showPopup = true
      // Set the offset of popup in relation to the cookie consent banner if present on screen
      const consentEle = document.getElementById('consent-banner')
      this.bottomOffset = consentEle ? document.getElementById('consent-banner').offsetHeight : 0

      if (this.lemonaid) {
        this.triggerAnalyticsEvent('popup shown', 'U.S. referral to LemonAid popup shown', 'Lemonaid popup')
      } else {
        this.triggerAnalyticsEvent('popup shown', 'Popup shown', 'Popup')
      }
    },

    closeModal () {
      this.showPopup = false
      this.setSessionStorage('displayPopups', false)

      if (this.lemonaid) {
        this.triggerAnalyticsEvent('button tap', 'Lemonaid Popup closed', 'Lemonaid popup')
      } else {
        this.triggerAnalyticsEvent('button tap', 'Popup closed', 'Popup')
      }
    },

    handleButtonClick (target, name, step, screen) {
      if (target) {
        window.location = target
      }
      this.triggerAnalyticsEvent(name, step, screen)
    },

    triggerAnalyticsEvent (name, step, screen) {
      TrackingProvider.trackEvent(name, {
        description: this.heading,
        step,
        screen
      })
    },

    setSessionStorage (key, value) {
      if (this.isSessionStorageAvailable) {
        const now = new Date()
        const expiry = now.getTime() + 3600000

        const item = {
          value,
          expiry
        }
        sessionStorage.setItem(key, JSON.stringify(item))
      }
    },

    getSessionStorage (key) {
      if (this.isSessionStorageAvailable) {
        const itemStr = sessionStorage.getItem(key)
        if (!itemStr) {
          return
        }

        const item = JSON.parse(itemStr)
        const now = new Date()

        if (now.getTime() > item.expiry) {
          sessionStorage.removeItem(key)
          return
        }
      } else {
        return
      }

      return true
    },

    showAlternativeLemonaidContent () {
      this.lemonaid = true

      if (this.alternativePopup.content !== undefined) {
        const items = [
          'heading',
          'body',
          'popupImage',
          'ctabuttontext',
          'ctabuttonlink'
        ]

        items.forEach(item => { this[item] = this.alternativePopup.content[item] })
      }
    },

    handleScroll () {
      const secondHeading = document.getElementsByTagName('h2')[1] || document.getElementsByTagName('html')[0]
      const fromTop = secondHeading.offsetTop || 2000
      const scrollAmount = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)

      if (scrollAmount > fromTop && !this.getSessionStorage('displayPopups')) {
        this.openModal()
        window.removeEventListener('scroll', this.handleScroll)
      }
    },

    checkIfSessionStorageAvailable () {
      try {
        const key = `__session__storage__test`
        sessionStorage.setItem(key, null)
        sessionStorage.removeItem(key)
        return true
      } catch (e) {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
@import "shared/site/patterns/vue/tokens";

$zava-blue: #0F62F4;
$asda-emarald-green: #538316;
$grey-shadow: rgba(0, 0, 0, 0.5);

.Bulma-component .Popup {
  animation-duration: 150ms;
  animation-name: fadeInUp;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 7px $grey-shadow;
  overflow: hidden;
  position: fixed;
  right: 0;
  width: 375px;
  z-index: 201;

  @media (max-width: 375px) {
    left: 0;
    width: 100%;
  }

  .modal-content  { // sass-lint:disable-line class-name-format
    border-radius: 8px;
    width: 305px;

    @media (min-width: 48em) {
      width: 496px;
    }
  }

  .modal-close { // sass-lint:disable-line class-name-format
    display: none;
  }
}

.Bulma-component .Popup-modal {
  background-color: $zava-blue;
  display: flex;

  .Popup-image-wrapper {
    display: inherit;
    width: 33%;
  }

  .Popup-image-wrapper img {
    align-self: flex-start;
    height: auto;
    width: 100%;
  }

  .Popup-content {
    padding: 15px 30px 15px 15px;
    width: 67%;
  }

  .Popup-content.Full-width {
    padding: 20px 30px;
    width: 100%;
  }

  .Popup-heading,
  .Popup-body,
  .Popup-body p,
  .Popup-body strong {
    color: $white;
    hyphens: auto;
  }

  .Popup-body strong {
    font-weight: bold;
  }

  .Popup-body a,
  .Popup-body a:active,
  .Popup-body a:focus,
  .Popup-body a:hover {
    color: $white;
    text-decoration: underline;
  }

  .Popup-heading {
    font-size: 16px;
    line-height: 24px;
  }

  .Popup-body p {
    font-weight: 300;
  }

  img {
    @media (min-width: 48em) {
      border-radius: 0;
    }
  }

  .Popup-buttons {
    display: flex;
    justify-content: space-between;
  }

  .Popup-buttons button.is-white,
  .Popup-buttons button.is-white:active,
  .Popup-buttons button.is-white:focus,
  .Popup-buttons button.is-white:hover {
    background: transparent;
    border: none;
    box-shadow: none;
    color: $white;
  }

  .Popup-buttons .Disabled {
    opacity: 0.24;
  }

  .Icon-close {
    color: $white;
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    z-index: 202;
  }

  &.Asda {
    background-color: $asda-emarald-green;
  }
}
</style>
